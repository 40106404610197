/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Container, Contacts, PageOffset} from 'components';
import {PageLayout as MDXLayout} from "components";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Container, null, React.createElement(_components.p, null, "Please contact me using the form below :)"), React.createElement(Contacts)), "\n", React.createElement(PageOffset));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
